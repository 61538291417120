.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Optional: Adds a semi-transparent overlay */
  z-index: 9999; /* Ensure the spinner appears above all elements */
}
