.gjs-pn-views .fa-cog {
  display: none;
}
.gjs-sm-sector-title {
  display: flex;
  align-items: center;
}
.gjs-category-title,
.gjs-layer-title,
.gjs-block-category .gjs-title,
.gjs-sm-sector-title {
  font-weight: lighter;
  background-color: rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  padding: 9px 10px 9px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: left;
  position: relative;
  cursor: pointer;
}
.gjs-sm-sector-label {
  margin-left: 5px;
}
.panel {
  width: 90%;
  max-width: 700px;
  border-radius: 3px;
  padding: 30px 20px;
  margin: 150px auto 0px;
  background-color: #d983a6;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
  color: rgba(255, 255, 255, 0.75);
  font: caption;
  font-weight: 100;
}

.welcome {
  text-align: center;
  font-weight: 100;
  margin: 0px;
}

.logo {
  width: 70px;
  height: 70px;
  vertical-align: middle;
}

.logo path {
  pointer-events: none;
  fill: none;
  stroke-linecap: round;
  stroke-width: 7;
  stroke: #fff;
}

.big-title {
  text-align: center;
  font-size: 3.5rem;
  margin: 15px 0;
}

.description {
  text-align: justify;
  font-size: 1rem;
  line-height: 1.5rem;
}
.gjs-devices {
  display: none;
}
